.emptyIcon {
  width: 50%;
}

.button {
  flex: 1;
  margin-top: 16px !important;
}

.buttons {
  margin-top: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
}

.input {
  flex-shrink: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  padding: 32px;
}

.appbar {
  align-items: flex-start;
}

.toolbar {
  width: 100%;
  box-sizing: border-box;
}

.searchIcon {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search {
  position: relative;
  border-radius: 25px;
  margin-left: 0;
  width: 100%;
}

.inputRoot {
  color: inherit !important;
  width: 100%;
}

.inputInput {
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  width: 100%;
}

.searchResult {
  padding: 8px;
  overflow-y: auto;
}

.subHeading {
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

.searchInstructions {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.notFound {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.progress {
  width: 24px !important;
  height: 24px !important;
}

.noSession {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
}

.sessions {
  padding: 16px;
  display: flex;
  flex: 1;
  justify-content: center;
}

.sessionsHeader {
  display: block;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.illustration {
  margin-left: 100px;
  margin-right: 100px;
}

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

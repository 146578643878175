.item {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
}

.itemText {
  display: flex;
}

.splitSlider {
  align-self: center;
}

.illustration {
  width: 48px;
  height: 48px;
}

.thumbnail {
  height: 128px;
  width: 128px;
}

.thumbnailWrapper {
  padding: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 32px;
}

.scrollContainer {
  overflow-y: auto;
}

.grid {
  display: grid;
  grid-template-columns: 32px auto 1fr;
  grid-auto-rows: 48px;
  align-items: center;
  margin-top: 0;
  justify-content: center;
  padding: 16px;
}

.genres {
  padding: 16px;
}

.genres * {
  float: left;
  margin-bottom: 8px;
}

.distribution {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.chart {
  margin-top: 8px;
  width: 40%;
  align-self: center;
}

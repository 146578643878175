.thumbnail {
  width: 80%;
  max-width: 400px;
  display: block;
}

.thumbnailWrapper {
  display: flex;
  justify-content: center;
  padding: 32px;
}

.headline {
  text-align: center;
}

.dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.musicPlayer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.bars {
  padding: 32px;
  padding-bottom: 16px;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 50px;
}

.gridItem {
  position: relative;
  margin-top: 16px;
}

.playButton {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.container {
  margin-top: 16px;
  overflow-x: hidden;
}

.containerEmpty {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.topLeft {
  padding: 16px;
  overflow: hidden;
}

.listitem {
  padding: 11px 16px 11px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 74px;
  align-items: center;
  cursor: pointer;
}

.bouncingListItem {
  transition: transform 0.5s ease-out;
  will-change: transform;
  padding: 11px 16px 11px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 74px;
  align-items: center;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 74px;
  padding-right: 16px;
  color: white;
  will-change: opacity;
  user-select: none;
}

.wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
}

.secondary {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
}

.illustration {
  margin-left: 100px;
  margin-right: 100px;
}

.button {
  margin-top: 32px !important;
}

.container {
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 32px;
  position: absolute;
}

.paper {
  padding: 32px;
  text-align: center;
  background-color: white;
}

.button {
  color: white !important;
  width: 100%;
  border-radius: 18px !important;
  margin-top: 32px !important;
}

a {
  text-decoration: none;
}

.link {
  text-decoration: none;
  color: white !important;
}

.illustration {
  height: 100%; /* height:width ratio = 9/16 = .5625  */
  z-index: -1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat !important;
  background-color: #fff !important;
}

.illustration > * {
  height: 100%;
  min-width: 100%;
}

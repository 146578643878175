.itemBehind {
  justify-content: flex-end;
  padding: 0 18px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 74px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  color: white;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 74px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.list {
  flex: 1;
  overflow-y: auto;
}

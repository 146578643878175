.playlist {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.fab {
  right: 16px !important;
  bottom: 86px !important;
  position: absolute !important;
}

.actions {
  margin-top: 16px;
  padding: 8px;
}

.selectDeselectButton {
  margin-right: auto !important; 
}

.thumbnail {
  height: 128px;
  width: 128px;
}

.thumbnailWrapper {
  padding: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
}

.scrollContainer {
  overflow-y: auto;
}

.qrcodePage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.illustrationWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: -100;
  overflow: hidden;
}

.illustrationFlex {
  display: flex;
  flex: 1;
  position: relative;
  justify-items: center;
  align-items: center;
  padding: 8px;
}

.illustration {
  width: 100%;
  opacity: 0.5;
}

.qrWrapper {
  padding: 16px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 25px;
}

.sessionInfo {
  position: absolute;
  bottom: 0;
  padding: 100px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  padding: 32px;
}

.appbar {
  align-items: flex-start;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: 'transparent';

  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  background: rgba(119, 119, 119, 0.54);
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  /* background: rgba(119, 119, 119, 0.692); */
  background-color: rgba(119, 119, 119, 0.719);
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.LogoText_logo__1ms-J {
  display: block;
  max-width: 100%;
}

.LoginPage_container__29XOo {
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 32px;
  position: absolute;
}

.LoginPage_paper__WCz_O {
  padding: 32px;
  text-align: center;
  background-color: white;
}

.LoginPage_button__2dbFp {
  color: white !important;
  width: 100%;
  border-radius: 18px !important;
  margin-top: 32px !important;
}

a {
  text-decoration: none;
}

.LoginPage_link__1FLcP {
  text-decoration: none;
  color: white !important;
}

.LoginPage_illustration__3XQC3 {
  height: 100%; /* height:width ratio = 9/16 = .5625  */
  z-index: -1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat !important;
  background-color: #fff !important;
}

.LoginPage_illustration__3XQC3 > * {
  height: 100%;
  min-width: 100%;
}

.LobbyPage_lobby__2J7PI {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
}

a {
  text-decoration: none;
}

.SessionList_list__2HzY2 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
}

.SessionList_fab__3q21C {
  position: absolute !important;
  right: 16px !important;
  bottom: 86px;
}

.SessionList_sessionList__2uQAd {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.SessionList_list__2HzY2 {
  flex: 1 1;
}

.SessionList_secondary__Mc9v4 {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.SessionList_listText__3sEff {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.SessionList_secondRow__ziSlE {
  display: flex;
  flex-direction: row;
}

.SwipeableList_itemBehind__2Sf4H {
  justify-content: flex-end;
  padding: 0 18px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 74px;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
}

.SwipeableList_item__14XiZ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 74px;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.SwipeableList_list__21R6_ {
  flex: 1 1;
  overflow-y: auto;
}

.SwipeableListItem_listitem__3HfJa {
  padding: 11px 16px 11px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 74px;
  align-items: center;
  cursor: pointer;
}

.SwipeableListItem_bouncingListItem__2JoGE {
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  will-change: transform;
  padding: 11px 16px 11px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 74px;
  align-items: center;
}

.SwipeableListItem_background__3U3lX {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 74px;
  padding-right: 16px;
  color: white;
  will-change: opacity;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.SwipeableListItem_wrapper__2LW4J {
  position: relative;
  -webkit-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  -webkit-transform-origin: top;
          transform-origin: top;
  overflow: hidden;
  width: 100%;
}

.SwipeableListItem_secondary__3kK8s {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.JoinSessionDialog_item__3Guj1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  display: flex;
  height: 100%;
  padding: 32px;
}

.JoinSessionDialog_appbar__2GmUk {
  align-items: flex-start;
}

.LobbyEmptyPlaceholder_emptyIcon__p_GzJ {
  width: 50%;
}

.LobbyEmptyPlaceholder_button__1w3a7 {
  flex: 1 1;
  margin-top: 16px !important;
}

.LobbyEmptyPlaceholder_buttons__2plxy {
  margin-top: 16px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.LobbyEmptyPlaceholder_content__15q6O {
  display: flex;
  flex-direction: column;
}

.LobbyEmptyPlaceholder_input__2CAwG {
  flex-shrink: 0;
}

.QrCodeScannerDialog_qrQrapper__1eg8U {
  display: flex;
  flex: 1 1;
  width: 300px;
}

.NewSessionDialog_actions__5QyZk {
  margin-top: 16px;
}

.NewSessionDialog_input__dwlX- {
  flex-shrink: 0;
}

.NewSessionDialog_importantText__1eL4Z {
  flex-shrink: 0;
}

.NewSessionDialog_illustration__D9tk- {
  margin-top: 16px;
  width: 50%;
  display: flex;
  flex: 1 1;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LeaveSessionDialog_actions__2Pyyy {
  margin-top: 16px;
}

.Appbar_drawerContent__tSYXT {
  width: 400px;
}

@media only screen and (max-width: 599px) {
  .Appbar_drawerContent__tSYXT {
    width: 300px;
  }
}

.Appbar_userInfo__Wi8QY {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  height: 300px;
}

.Appbar_placeholder__1IsL4 {
  width: 50px;
  height: 50px;
  left: 16px;
  top: 16px;
  border-radius: 50%;
  margin-right: 16px;
}

.Appbar_userInfoTextWrapper__2YdGN {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 50px;
}
.Appbar_userInfoText__3UYTS {
  margin: 0;
}

.Appbar_userInfoWrapper__1uFco {
  display: flex;
}

.Appbar_drawerLogo__1Jau0 {
  display: flex;
  align-items: flex-end;
  flex: 1 1;
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 16px;
}

.Appbar_appbar__1XAqf {
  flex-direction: row !important;
  align-items: center;
}

.Appbar_logo__1mXAQ {
  width: 50%;
}

.Appbar_noUserSelect__VjoM0 {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Appbar_grow__1Who4 {
  flexgrow: 1;
}

.Appbar_defaultAvatar__37udK {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.Appbar_illustration__1Nh_H {
  position: absolute;
  width: 600px;
  height: 300px;
  z-index: -1;
  margin: -16px;
  overflow: hidden;
}

.Appbar_sessionInfo__yT2cO {
  margin-bottom: 8px;
  margin-top: 8px;
}

.Appbar_sessionInfo__yT2cO > p {
  margin: 0;
}

.DashboardPage_thumbnail__1STl3 {
  width: 80%;
  max-width: 400px;
  display: block;
}

.DashboardPage_thumbnailWrapper__16vcb {
  display: flex;
  justify-content: center;
  padding: 32px;
}

.DashboardPage_headline__3fGyB {
  text-align: center;
}

.DashboardPage_dashboard__2ymuK {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.DashboardPage_musicPlayer__nL7WV {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.DashboardPage_bars__29uTi {
  padding: 32px;
  padding-bottom: 16px;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 50px;
}

.DashboardPage_gridItem__2eFUF {
  position: relative;
  margin-top: 16px;
}

.DashboardPage_playButton__Nm_rB {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardPage_progress__HJoaD {
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.DashboardPage_container__eLyWp {
  margin-top: 16px;
  overflow-x: hidden;
}

.DashboardPage_containerEmpty__16tmh {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-evenly;
  align-items: center;
}

.DashboardPage_actions__17tf_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashboardPage_top__2P8fD {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.DashboardPage_topLeft__37ti_ {
  padding: 16px;
  overflow: hidden;
}

.TrackList_list__WRLmv {
  flex: 1 1;
}

.TrackList_secondary__3Ob5l {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.TrackList_listText__3M2A1 {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.TrackList_secondRow__16ApH {
  display: flex;
  flex-direction: row;
}

.TrackDetailDialog_thumbnail__r7pvF {
  height: 128px;
  width: 128px;
}

.TrackDetailDialog_thumbnailWrapper__rF9Zs {
  padding: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 32px;
}

.TrackDetailDialog_scrollContainer__3Yc0W {
  overflow-y: auto;
}

.TrackDetailDialog_grid__3cwd5 {
  display: grid;
  grid-template-columns: 32px auto 1fr;
  grid-auto-rows: 48px;
  align-items: center;
  margin-top: 0;
  justify-content: center;
  padding: 16px;
}

.TrackDetailDialog_genres__28fCD {
  padding: 16px;
}

.TrackDetailDialog_genres__28fCD * {
  float: left;
  margin-bottom: 8px;
}

.Player2_root__12lBz {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.Player2_startTime__rq8zH {
  padding-right: 16px;
  width: 60px;
  font-weight: 600;
}

.Player2_endTime__3rwe9 {
  padding-left: 16px;
  font-weight: 600;
  width: 60px;
}

.Player2_time__28k6l {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 48px;
  padding-bottom: 8px;
}

.Player2_playIcon__1ra4V {
  height: 32px !important;
  width: 32px !important;
}

.Player2_playWrapper__2y0WD {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.Player2_whitespace__uzvDK {
  position: relative;
  border-radius: 50%;
  margin-top: -32px;
}

.Player2_controls__IekY_ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  padding-top: 50px;
}

.Player2_progressBar__1IIJJ {
}

.Player2_wrapper__35N1z {
  margin-right: -50%;
}

.Player2_player__RjF7n {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PlaylistPage_playlist__3wsJt {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.PlaylistPage_fab__10l15 {
  right: 16px !important;
  bottom: 86px !important;
  position: absolute !important;
}

.UserTrackList_list__1hT8- {
  flex: 1 1;
}

.UserTrackList_secondary__JwYNE {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.UserTrackList_moveVertButton__3Qk5t {
  flex: 0 1 auto;
  position: inherit !important;
  -webkit-transform: unset !important;
          transform: unset !important;
  cursor: pointer;
}

.UserTrackList_listText__280Fa {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.UserTrackList_secondRow__1sW9p {
  display: flex;
  flex-direction: row;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Search_item__1qn0z {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  display: flex;
  height: 100%;
  padding: 32px;
}

.Search_appbar__1WL2z {
  align-items: flex-start;
}

.Search_toolbar__2HAFa {
  width: 100%;
  box-sizing: border-box;
}

.Search_searchIcon__138Ir {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Search_search__3X3f3 {
  position: relative;
  border-radius: 25px;
  margin-left: 0;
  width: 100%;
}

.Search_inputRoot__JpjO3 {
  color: inherit !important;
  width: 100%;
}

.Search_inputInput__2OKI0 {
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  width: 100%;
}

.Search_searchResult__1MTBJ {
  padding: 8px;
  overflow-y: auto;
}

.Search_subHeading__4SOfJ {
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

.Search_searchInstructions__1ZYgj {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.Search_notFound__3Jchc {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.Search_progress__f64al {
  width: 24px !important;
  height: 24px !important;
}

.AlbumList_thumbnail__1WXp8 {
  height: 100px;
  width: 100px;
}

.AlbumList_thumbnailWrapper__GIy3w {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.AlbumList_horizontalItem__2_7Ls {
  width: 116px;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 8px;
}

.AlbumList_listWrapper__35Tk5 {
  overflow-x: auto;
}

.AlbumList_subHeading__Ookwe {
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

.PlaylistList_thumbnail__fFzZ5 {
  height: 100px;
}

.PlaylistList_thumbnailWrapper__2-3Iz {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.PlaylistList_horizontalItem__ryH_d {
  width: 116px;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 8px;
}

.PlaylistList_listWrapper__2u-d1 {
  overflow-x: auto;
}

.PlaylistList_subHeading__FT6N3 {
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

.AddAlbumDialog_actions__1kXxU {
  margin-top: 16px;
  padding: 8px;
}

.AddAlbumDialog_selectDeselectButton__3KfXp {
  margin-right: auto !important; 
}

.AddAlbumDialog_thumbnail__3RF7M {
  height: 128px;
  width: 128px;
}

.AddAlbumDialog_thumbnailWrapper__3zCzx {
  padding: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
}

.AddAlbumDialog_scrollContainer__3LACu {
  overflow-y: auto;
}


.TrackList_list__NsWgI {
  width: 100%;
  flex: 1 1;
  overflow-x: hidden;
}

.ShuffleUserPlaylistDialog_actions__3eNAZ {
    margin-top: 16px;
  }

  .ShuffleUserPlaylistDialog_randomIllustration__o0nwI {
      padding: 20px;
  }
.EmptyUserPlaylistDialog_actions__311qi {
    margin-top: 16px;
}

.EmptyUserPlaylistDialog_emptyIllustration__13GA3 {
    padding: 20px;
}
.FullpageLoading_loading__1VbK1 {
  position: absolute;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.FullpageLoading_text__3jEvD {
  margin-top: 16px !important;
}

.FullpageLoading_spinner__1pTO9 {
  opacity: 1;
  overflow: hidden;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.VinylSpinner_spinner___C5Wx {
  -webkit-animation: VinylSpinner_spin__1iIqe 4s linear infinite;
  animation: VinylSpinner_spin__1iIqe 4s linear infinite;
  width: 200px;
}
@-webkit-keyframes VinylSpinner_spin__1iIqe {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes VinylSpinner_spin__1iIqe {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.EmptyPlaylistPlaceholder_root__3GrrI {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
}

.EmptyPlaylistPlaceholder_illustration__2hYdM {
  margin-left: 100px;
  margin-right: 100px;
}

.EmptyPlaylistPlaceholder_button__3sydD {
  margin-top: 32px !important;
}

.QrCodePage_qrcodePage__1WsGY {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.QrCodePage_illustrationWrapper__3v4Nh {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: -100;
  overflow: hidden;
}

.QrCodePage_illustrationFlex__IteBP {
  display: flex;
  flex: 1 1;
  position: relative;
  justify-items: center;
  align-items: center;
  padding: 8px;
}

.QrCodePage_illustration__20zn9 {
  width: 100%;
  opacity: 0.5;
}

.QrCodePage_qrWrapper__3LCon {
  padding: 16px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 25px;
}

.QrCodePage_sessionInfo__7qkDO {
  position: absolute;
  bottom: 0;
  padding: 100px;
}

.SettingsPage_settings__2BPNG {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 16px;
}

.DistributionPage_distribution__2i8MF {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.DistributionPage_chart__31rwO {
  margin-top: 8px;
  width: 40%;
  align-self: center;
}

.DistributionListItem_item__3Q5Tf {
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
}

.DistributionListItem_itemText__2JRJI {
  display: flex;
}

.DistributionListItem_splitSlider__2eXlG {
  align-self: center;
}

.DistributionListItem_illustration__3h9w2 {
  width: 48px;
  height: 48px;
}


.SessionSettingsPage_root__1F-DP {
  padding: 32px;
}

.SessionSettingsPage_row__vzIcC {
  margin-bottom: 64px;
}

.JoinSessionPage_noSession__2unEr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  display: flex;
  height: 100%;
}

.JoinSessionPage_item__1SbpH {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  display: flex;
  height: 100%;
}

.JoinSessionPage_sessions__8YJsK {
  padding: 16px;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.JoinSessionPage_sessionsHeader__1NKWQ {
  display: block;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.JoinSessionPage_illustration__1GqnN {
  margin-left: 100px;
  margin-right: 100px;
}

.JoinSessionPage_loading__2fcrQ {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}


.thumbnail {
  height: 100px;
}

.thumbnailWrapper {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.horizontalItem {
  width: 116px;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 8px;
}

.listWrapper {
  overflow-x: auto;
}

.subHeading {
  margin-left: 8px !important;
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

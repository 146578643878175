.list {
  flex: 1;
}

.secondary {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.listText {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.secondRow {
  display: flex;
  flex-direction: row;
}

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.fab {
  position: absolute !important;
  right: 16px !important;
  bottom: 86px;
}

.sessionList {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.list {
  flex: 1;
}

.secondary {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.listText {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.secondRow {
  display: flex;
  flex-direction: row;
}

.actions {
  margin-top: 16px;
}

.input {
  flex-shrink: 0;
}

.importantText {
  flex-shrink: 0;
}

.illustration {
  margin-top: 16px;
  width: 50%;
  display: flex;
  flex: 1;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

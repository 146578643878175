.drawerContent {
  width: 400px;
}

@media only screen and (max-width: 599px) {
  .drawerContent {
    width: 300px;
  }
}

.userInfo {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  height: 300px;
}

.placeholder {
  width: 50px;
  height: 50px;
  left: 16px;
  top: 16px;
  border-radius: 50%;
  margin-right: 16px;
}

.userInfoTextWrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 50px;
}
.userInfoText {
  margin: 0;
}

.userInfoWrapper {
  display: flex;
}

.drawerLogo {
  display: flex;
  align-items: flex-end;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 16px;
}

.appbar {
  flex-direction: row !important;
  align-items: center;
}

.logo {
  width: 50%;
}

.noUserSelect {
  user-select: none;
}

.grow {
  flexgrow: 1;
}

.defaultAvatar {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.illustration {
  position: absolute;
  width: 600px;
  height: 300px;
  z-index: -1;
  margin: -16px;
  overflow: hidden;
}

.sessionInfo {
  margin-bottom: 8px;
  margin-top: 8px;
}

.sessionInfo > p {
  margin: 0;
}

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.startTime {
  padding-right: 16px;
  width: 60px;
  font-weight: 600;
}

.endTime {
  padding-left: 16px;
  font-weight: 600;
  width: 60px;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 48px;
  padding-bottom: 8px;
}

.playIcon {
  height: 32px !important;
  width: 32px !important;
}

.playWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.whitespace {
  position: relative;
  border-radius: 50%;
  margin-top: -32px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  padding-top: 50px;
}

.progressBar {
}

.wrapper {
  margin-right: -50%;
}

.player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

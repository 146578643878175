.loading {
  position: absolute;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  opacity: 1;
  transition: opacity 0.5s ease;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.text {
  margin-top: 16px !important;
}

.spinner {
  opacity: 1;
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.5s ease;
}

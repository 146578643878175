.lobby {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

a {
  text-decoration: none;
}

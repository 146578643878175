.list {
  flex: 1;
}

.secondary {
  text-align: 'right' !important;

  width: '4em' !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.moveVertButton {
  flex: 0 1 auto;
  position: inherit !important;
  transform: unset !important;
  cursor: pointer;
}

.listText {
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.secondRow {
  display: flex;
  flex-direction: row;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
